<template>
  <div v-if="isServerDataReady" class="custom-content-height">
    <div class="h-100 bg-white">
      <div class="py-8 pl-4"><h2>Order Status</h2></div>
      <div>
        <v-card-text class="pt-3" v-if="upload_trigger">
          <v-alert
            dense
            border="left"
            type="warning"
            v-if="exampleFile.need_to_show_message"
            dismissible
          >
            <span v-html="exampleFile.message"></span>
          </v-alert>
          <!-- {{ exampleURl }} -->
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <v-select
                clearable
                dense
                outlined
                label="Carrier"
                v-model="file_upload.carrier"
                :items="carriers"
                item-text="name"
                item-value="id"
                class="px-2"
                :error-messages="carrierErrors"
                @input="$v.file_upload.carrier.$touch()"
                @blur="$v.file_upload.carrier.$touch()"
              ></v-select>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <v-select
                clearable
                dense
                outlined
                label="System"
                v-model="file_upload.system"
                :items="serverData.systems"
                item-text="name"
                item-value="id"
                class="px-2"
                :error-messages="systemErrors"
                @input="$v.file_upload.system.$touch()"
                @blur="$v.file_upload.system.$touch()"
              ></v-select>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <v-file-input
                v-model="file_upload.file"
                clearable
                ref="file_uploader"
                label="File input"
                outlined
                class="px-2"
                dense
                :error-messages="fileErrors"
                @input="$v.file_upload.file.$touch()"
                @blur="$v.file_upload.file.$touch()"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-3">
          <div v-html="upload_progress"></div>
          <div class="pre-scrollable" v-html="upload_message_data"></div>
        </v-card-text>
        <v-card-actions v-if="upload_trigger" class="mx-4">
          <v-spacer></v-spacer>
          <v-btn
            class="primary--text elevation-3"
            outlined
            text
            :href="exampleFile.url"
          >
            Download Template
          </v-btn>
          <v-btn
            class="elevation-3 iq-light"
            @click="file_upload['modal'] = false"
          >
            Cancel
          </v-btn>
          <button class="btn iq-btn px-3 mx-3 poppins-ls" @click="fileUploader">
            <span>Upload</span>
          </button>
        </v-card-actions>
        <v-card-actions class="text-right" v-if="upload_end">
          <v-btn d-flex class="white--text primary" @click="fileUploader">
            <span>Done</span>
          </v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import getPusherInstance from "@/core/services/pusher.service";

export default {
  name: "Datatable",
  mixins: [validationMixin],
  validations: {
    file_upload: {
      file: { required },
      carrier: { required },
      system: { required },
    },
  },
  data: () => ({
    serverData: null,
    carriers: null,
    exampleFile: null,

    upload_trigger: true,
    upload_message_data: "",
    upload_end: false,
    upload_progress: "",
    upload_load: false,
    upload_status_total: 0,
    upload_status_suc: 0,
    file_upload: {
      modal: false,
      file: null,
      carrier: null,
      system: null,
    },
  }),

  beforeMount() {
    // console.log("socket data ", this.userSocketData);

    this.loadDataFromServer();
  },
  computed: {
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    carrierErrors() {
      return this.handleFormValidation("carrier", this);
    },
    systemErrors() {
      return this.handleFormValidation("carrier", this);
    },
    isServerDataReady: function () {
      return !!this.serverData;
    },
    userSocketData: function () {
      return this.$store.getters.getSocketData;
    },
    upload_loader: {
      get: function () {
        return this.upload_load;
      },
      set: function (newValue) {
        this.upload_load = newValue;
      },
    },
    upload_message: function () {
      return this.upload_message_data;
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.file_upload[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.file_upload[fieldName]) {
        !vueObj.$v.file_upload[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/orderStatus")
        .then((response) => {
          this.serverData = response.data;
          this.exampleFile = { ...this.serverData.files[0] };
          this.carriers = [...this.serverData.carriers];
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    socketListen: function (eventName) {
      const pusher = getPusherInstance();
      const channel = pusher.subscribe(this.userSocketData.channel);
      channel.bind(`${eventName}`, (notification) => {
        // console.log(notification);
        let style = "black--text";
        if (notification.type == "start") {
          this.upload_status_total = notification.total;
          style = "blue--text";
        } else if (notification.type == "end") {
          this.upload_end = true;
          style = "blue--text";
          // this.updateTableContent();
        } else if (notification.type == "info") {
          style = "info--text";
        } else if (notification.type == "success") {
          style = "success--text";
          this.upload_status_suc += 1;
        } else if (notification.type == "error") {
          style = "red--text";
        } else if (notification.type == "popUpConfirmationMessage") {
          // TODO: put sweet alert with confirm
          // console.log("pop up one  is   ", notification);
          let data = { token: notification.token };
          Swal.fire({
            title: "Upload warning!",
            html: notification.message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, apply changes!",
          }).then((result) => {
            if (result.isConfirmed) {
              ApiService.post("/fulfillment/orders/confirm", data)
                .then(() => {
                  Swal.fire("Confirmed!", "Upload is resumed", "success");
                })
                .catch(() => {
                  this.upload_end = true;
                  this.upload_trigger = false;
                });
            } else {
              this.upload_end = true;
              this.upload_trigger = false;
            }
          });
        }

        this.upload_progress = `<h3>${this.upload_status_suc}/${this.upload_status_total}</h3>`;
        let message = `<h6 class="${style}">
                            <span>${notification.message}</span>
                         </h6>`;
        if (notification.type != "success") {
          this.upload_message_data += message;
        }
      });
    },
    fileUploader() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.file_upload.file && this.file_upload.carrier) {
          if (this.upload_trigger) {
            this.upload_trigger = false;
            this.upload_load = true;

            this.upload_message_data = "Uploading...";
            // console.log("file value", this.file_upload.file);
            let formData = new FormData();
            let file = this.file_upload.file;
            if (file) {
              formData.append("file", file, file.name);
              formData.append("carrier_id", this.file_upload.carrier);
              ApiService.post("/shipping/orderStatus/upload", formData)
                .then((data) => {
                  this.upload_message_data =
                    "<h3 class='success--text'>Reading</h3>";
                  this.upload_trigger = false;
                  this.socketListen(data.data.event);
                })
                .catch((res) => {
                  this.upload_message_data += res;
                  this.upload_end = true;
                  this.upload_trigger = false;
                });
            } else {
              this.upload_message_data = "Please select a carrier!";
            }
          } else {
            this.$v.$reset();
            this.upload_trigger = true;
            this.upload_message_data = "";
            this.file_upload["modal"] = false;
            this.upload_load = false;
            this.upload_end = false;
            this.file_upload["file"] = null;
            this.upload_progress = "";
            this.upload_status_total = 0;
            this.upload_status_suc = 0;
            this.file_upload.carrier = null;
            this.$store.commit(SET_PAGE_LOADING, true);
            this.$store.commit(SET_PAGE_LOADING, false);
            // Swal.fire({
            //   title: "",
            //   text: ``,
            //   icon: "error",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false,
            // });
          }
        } else {
          Swal.fire({
            title: "Data required!",
            text: `Please, fill the form!`,
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
    toggleUploadModal() {
      this.file_upload.modal = !this.file_upload.modal;
    },
  },
};
</script>
